import React, { useEffect, useState } from "react";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { collection, query, getFirestore, getDocs } from "firebase/firestore";
import app from "utils/firebase";

const AllData = () => {
  const db = getFirestore(app);

  const [mainArr, setMainArr] = useState([]);
  async function getMarker() {
    const q = query(collection(db, "users"));
    const querySnapshot = await getDocs(q);
    const tempArr = [];
    querySnapshot.forEach((doc) => {
      tempArr.push(doc.data());
    });
    setMainArr(tempArr);
  }

  useEffect(() => {
    getMarker();
  }, []);

  return (
    <PageLayout background="white">
      <p>{JSON.stringify(mainArr)}</p>
    </PageLayout>
  );
};

export default AllData;
