import React, { useState } from "react";
import PropTypes from "prop-types";

export const UserContext = React.createContext();
const AuthContext = ({ children }) => {
  const [user, setUser] = useState();
  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

AuthContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;  // eslint-disable-line
