import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FeedCard from "examples/Cards/FeedCard";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "context/UserContext";
import { collection, getFirestore, onSnapshot, orderBy, query } from "firebase/firestore";
import app from "utils/firebase";

function Feed() {
  const { user } = useContext(UserContext);
  const db = getFirestore(app);
  const [userVideos, setUserVideos] = useState([]);

  const callback = async (entries) => {
    entries.forEach((element) => {
      const child = element.target.childNodes[0];
      const id = child.getAttribute("id");
      const el = document.getElementById(`${id}`);
      el.play().then(() => {
        el.muted = false;
        if (!el.paused && element.isIntersecting !== true) {
          el.pause();
        }
      });
    });
  };

  const observer = new IntersectionObserver(callback, {
    threshold: 0.85,
  });

  useEffect(() => {
    if (typeof window === "object") {
      const elements = document.querySelectorAll(".post_video");
      elements.forEach((el) => {
        observer.observe(el);
      });
      return () => {
        observer.disconnect();
      };
    }
    return 0;
    // eslint-disable-next-line
  }, [userVideos]);

  const q = query(collection(db, "posts"), orderBy("createdAt", "desc"));
  const getUserFeed = () => {
    onSnapshot(
      q,
      (snapshot) => {
        const posts = [];
        snapshot.forEach((doc) => {
          posts.push(doc.data());
        });
        setUserVideos(posts);
      },
      (error) => {
        console.log(error); // eslint-disable-line
      }
    );
  };

  useEffect(() => {
    if (user && user.uid) {
      getUserFeed();
    }
  }, [user]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={8} style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div
          className="video-container"
          style={{
            backgroundColor: "#f4f4f4",
            height: "500px",
            width: "300px",
            overflowY: "scroll",
            scrollSnapType: "y mandatory",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            WebkitOverflowScrolling: "none",
          }}
        >
          {userVideos &&
            userVideos?.length !== 0 &&
            userVideos.map((item) => (
              <FeedCard
                video={item.videoLink}
                likes={item.likes}
                uid={item.uid}
                key={item.pid}
                id={item.pid}
                userMail={item.email}
              />
            ))}
        </div>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Feed;
