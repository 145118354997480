import Profile from "layouts/profile";
import Feed from "layouts/feed";
import Icon from "@mui/material/Icon";
import BMI from "layouts/bmi";
import Exercise from "layouts/exercise";
import Events from "layouts/events";

const routes = [
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Feed",
    key: "feed",
    icon: <Icon fontSize="small">rss_feed</Icon>,
    route: "/feed",
    component: <Feed />,
  },
  {
    type: "collapse",
    name: "BMI",
    key: "bmi",
    icon: <Icon fontSize="small">healing</Icon>,
    route: "/bmi",
    component: <BMI />,
  },
  {
    type: "collapse",
    name: "Exercise",
    key: "exercise",
    icon: <Icon fontSize="small">fitness_center</Icon>,
    route: "/exercise",
    component: <Exercise />,
  },
  {
    type: "collapse",
    name: "Events",
    key: "events",
    icon: <Icon fontSize="small">event</Icon>,
    route: "/events",
    component: <Events />,
  },
];

export default routes;
