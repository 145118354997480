import React, { useContext, useState } from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/material/styles";
import { UserContext } from "context/UserContext";
import { Button, CardMedia } from "@mui/material";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import app from "utils/firebase";
import { arrayUnion, doc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import PropTypes from "prop-types";

const Input = styled("input")({
  display: "none",
});

const NewReel = ({ userVideos, setUserVideos }) => {
  const { user } = useContext(UserContext);
  const storage = getStorage(app);
  const db = getFirestore(app);
  const [video, setVideo] = useState(null);

  const today = new Date();
  const [newReel, setNewReel] = useState({
    email: user.email,
    pid: uuidv4(),
    videoLink: "",
    likes: [],
    createdAt: today,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const videoRef = ref(storage, `images/${newReel.email}/${newReel.pid}.mp4`);
    await uploadBytes(videoRef, video).then((snapshot) => {
      getDownloadURL(snapshot.ref).then(async (downloadURL) => {
        setNewReel({
          ...newReel,
          videoLink: downloadURL,
        });
        try {
          await setDoc(doc(db, "posts", newReel.pid), {
            ...newReel,
            videoLink: downloadURL,
          });
          await updateDoc(doc(db, "users", user.uid), {
            posts_created: arrayUnion(newReel.pid),
          });
          setUserVideos([
            ...userVideos,
            {
              ...newReel,
              videoLink: downloadURL,
            },
          ]);
          alert("video uploaded"); // eslint-disable-line
        } catch (error) {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(errorCode, errorMessage); // eslint-disable-line
        }
      });
    });
  };

  return (
    <div>
      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
        Upload New Reel
      </MDTypography>
      <MDBox component="form" role="form">
        {video ? (
          <CardMedia
            component="video"
            height="250"
            image={URL.createObjectURL(video)}
            alt="video"
          />
        ) : (
          <MDBox mb={2}>
            <label htmlFor="video">
              <Input
                accept="video/*"
                id="video"
                type="file"
                onChange={(e) => setVideo(e.target.files[0])}
              />
              <Button variant="contained" component="span" style={{ color: "#fff" }}>
                Select Video
              </Button>
            </label>
          </MDBox>
        )}
        <MDBox mt={4} mb={1}>
          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
            Upload
          </MDButton>
        </MDBox>
      </MDBox>
    </div>
  );
};

NewReel.propTypes = {
  userVideos: PropTypes.array.isRequired,
  setUserVideos: PropTypes.func.isRequired,
};

export default NewReel;
