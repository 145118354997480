import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import app from "utils/firebase";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { getFirestore, setDoc, doc } from "firebase/firestore";
import { useState } from "react";

const Input = styled("input")({
  display: "none",
});

function Cover() {
  const auth = getAuth(app);
  const storage = getStorage(app);
  const db = getFirestore(app);

  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    photoUrl: "",
  });
  const [photo, setPhoto] = useState(null);
  const handleChange = (e) => {
    const val = e.target.value;
    const name = e.target.name; // eslint-disable-line
    setData({ ...data, [name]: val });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const imgRef = ref(storage, `images/${data.email}/photo.jpg`);
    await uploadBytes(imgRef, photo)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setData({ ...data, photoUrl: downloadURL });
          createUserWithEmailAndPassword(auth, data.email, data.password)
            .then(async ({ user }) => {
              // Signed in
              try {
                await setDoc(doc(db, "users", user.uid), {
                  name: data.name,
                  email: data.email,
                  posts_created: [],
                  photoUrl: downloadURL,
                  uid: user.uid,
                  bmi: "",
                  height: "",
                  weight: "",
                  gender: "",
                  age: "",
                });
                navigate("/login");
              } catch (error) {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorCode, errorMessage); // eslint-disable-line
              }
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              alert(errorMessage); // eslint-disable-line
              console.error(errorCode, errorMessage); // eslint-disable-line
              // ..
            });
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage); // eslint-disable-line
        console.error(errorCode, errorMessage); // eslint-disable-line
      });
  };
  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Name"
                variant="standard"
                fullWidth
                name="name"
                onChange={handleChange}
                value={data.name}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                name="email"
                onChange={handleChange}
                value={data.email}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                name="password"
                onChange={handleChange}
                value={data.password}
              />
            </MDBox>
            <MDBox mb={2}>
              <label htmlFor="photo">
                <Input
                  accept="image/*"
                  id="photo"
                  type="file"
                  onChange={(e) => setPhoto(e.target.files[0])}
                />
                <Button variant="contained" component="span" style={{ color: "#fff" }}>
                  Upload Photo
                </Button>
              </label>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                sign up
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
