import React, { useContext, useEffect, useState } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Card, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { UserContext } from "context/UserContext";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import app from "utils/firebase";

function BMI() {
  const { user } = useContext(UserContext);
  const db = getFirestore(app);

  const [uData, setUData] = useState("");
  const [data, setData] = useState({
    height: "",
    weight: "",
    gender: "",
    age: "",
  });

  const getBmi = async () => {
    getDoc(doc(db, "users", user.uid))
      .then(async (querySnapshot) => {
        if (querySnapshot.exists()) {
          const uD = querySnapshot.data();
          if (uD.bmi && uD.bmi !== "") {
            setUData(uD.bmi);
            setData({
              height: uD.height,
              weight: uD.weight,
              gender: uD.gender,
              age: uD.age,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
      });
  };
  useEffect(() => {
    if (user && user.uid) {
      getBmi();
    }
  }, [user]);

  const handleChange = (e) => {
    const val = e.target.value;
    const name = e.target.name; // eslint-disable-line
    setData({ ...data, [name]: val });
  };

  const handleSubmit = async () => {
    const a = (data.weight / data.height / data.height) * 10000;
    setUData(a.toFixed(2));
    await updateDoc(doc(db, "users", user.uid), {
      bmi: a.toFixed(2),
      height: data.height,
      weight: data.weight,
      gender: data.gender,
      age: data.age,
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={5}>
        <Grid item xs={12} xl={8}>
          <Card>
            <MDBox
              // variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              // mx={2}
              p={2}
              textAlign="center"
              mt={2}
              mb={2}
              style={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Calculate BMI
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    name="height"
                    label="Height (in cm)"
                    required
                    fullWidth
                    onChange={handleChange}
                    value={data.height}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    name="weight"
                    label="Weight (in kg)"
                    required
                    fullWidth
                    onChange={handleChange}
                    value={data.weight}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <FormControl fullWidth>
                    <InputLabel id="gender-simple-select-label">Gender *</InputLabel>
                    <Select
                      labelId="gender-simple-select-label"
                      name="gender"
                      label="Gender *"
                      required
                      onChange={handleChange}
                      value={data.gender}
                      style={{ height: "45px" }}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    name="age"
                    label="Age"
                    required
                    fullWidth
                    onChange={handleChange}
                    value={data.age}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                    Calculate
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12} xl={4}>
          {uData && uData !== "" && (
            <Card>
              <MDBox pt={3} pb={3} px={3}>
                <MDTypography variant="h2" fontWeight="medium" color="inherit" mt={1}>
                  Your BMI
                </MDTypography>
                <MDTypography variant="h4" color="inherit" mt={1}>
                  {uData}
                </MDTypography>
                {parseFloat(uData) < 18.5 && (
                  <MDTypography variant="h6" color="info" mt={1}>
                    Underweight
                  </MDTypography>
                )}
                {parseFloat(uData) > 18.5 && parseFloat(uData) < 24.9 && (
                  <MDTypography variant="h6" color="success" mt={1}>
                    Normal Weight
                  </MDTypography>
                )}
                {parseFloat(uData) > 25 && parseFloat(uData) < 29.9 && (
                  <MDTypography variant="h6" color="#b2a429" mt={1}>
                    Overweight
                  </MDTypography>
                )}
                {parseFloat(uData) > 30 && (
                  <MDTypography variant="h6" color="warning" mt={1}>
                    Obesity
                  </MDTypography>
                )}
              </MDBox>
            </Card>
          )}
          <Card style={{ marginTop: "2.5rem" }}>
            <MDBox pt={3} pb={3} px={3}>
              <MDTypography variant="h4" fontWeight="medium" color="inherit" mt={1}>
                BMI Analysis
              </MDTypography>
              <MDTypography variant="h6" color="inherit" mt={1}>
                Check our BMI Analysis {` `}
                <a
                  href="https://colab.research.google.com/drive/1zqqDn3MXP_3vqqeAi7cVKjsriqxo72Vz?usp=sharing"
                  alt="Google Colab"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                .
              </MDTypography>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default BMI;
