import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
} from "examples/Navbars/DashboardNavbar/styles";
import { useMaterialUIController, setTransparentNavbar } from "context";
import { UserContext } from "context/UserContext";
import { Avatar } from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import app from "utils/firebase";
import DefaultNavbarLink from "../DefaultNavbar/DefaultNavbarLink";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const auth = getAuth(app);
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }
    window.addEventListener("scroll", handleTransparentNavbar);

    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("user");
        setUser(null);
        navigate("/login");
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
      });
  };

  const handleClick = () => {
    navigate("/profile");
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {user && (
        <div>
          <NotificationItem
            icon={<Icon>badge</Icon>}
            title={user.name}
            style={{ cursor: "default" }}
          />
          <NotificationItem
            icon={<Icon>alternate_email</Icon>}
            title={user.email}
            style={{ cursor: "default" }}
          />
          <NotificationItem icon={<Icon>logout</Icon>} title="Logout" onClick={handleLogout} />
        </div>
      )}
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        {user && user !== {} ? (
          <>
            {route[0] === "" ? (
              <MDBox
                color="inherit"
                mb={{ xs: 1, md: 0 }}
                sx={(theme) => navbarRow(theme, { isMini })}
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              >
                FitSane
              </MDBox>
            ) : (
              <MDBox
                color="inherit"
                mb={{ xs: 1, md: 0 }}
                sx={(theme) => navbarRow(theme, { isMini })}
              >
                <Breadcrumbs
                  icon="home"
                  title={route[route.length - 1]}
                  route={route}
                  light={light}
                />
              </MDBox>
            )}
            {isMini ? null : (
              <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
                <MDBox color={light ? "white" : "inherit"}>
                  {user && (
                    <>
                      <Avatar
                        alt={user.displayName}
                        src={user.photoUrl}
                        onClick={handleOpenMenu}
                        aria-haspopup="true"
                        aria-controls="notification-menu"
                        color="inherit"
                        sx={{
                          ...navbarIconButton,
                          height: "50px",
                          width: "50px",
                          cursor: "pointer",
                        }}
                      />
                      {renderMenu()}
                    </>
                  )}
                </MDBox>
              </MDBox>
            )}
          </>
        ) : (
          <>
            <MDBox
              color="inherit"
              mb={{ xs: 1, md: 0 }}
              sx={(theme) => navbarRow(theme, { isMini })}
            >
              FitSane
            </MDBox>
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              <MDBox color={light ? "white" : "inherit"}>
                {user ? (
                  <>
                    <Avatar
                      alt={user.displayName}
                      src={user.photoUrl}
                      onClick={handleOpenMenu}
                      aria-haspopup="true"
                      aria-controls="notification-menu"
                      color="inherit"
                      sx={{
                        ...navbarIconButton,
                        height: "50px",
                        width: "50px",
                        cursor: "pointer",
                      }}
                    />
                    {renderMenu()}
                  </>
                ) : (
                  <>
                    <MDBox color="inherit" display={{ xs: "none", lg: "flex" }} m={0} p={0}>
                      <DefaultNavbarLink
                        icon="account_circle"
                        name="sign up"
                        route="/signup"
                        light={light}
                      />
                      <DefaultNavbarLink icon="key" name="sign in" route="/login" light={light} />
                    </MDBox>
                  </>
                )}
              </MDBox>
            </MDBox>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
