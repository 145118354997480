import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAzME1g0r3PtImVluZdR2iWOfxjXV5hDG4",
  authDomain: "fitsane-3d448.firebaseapp.com",
  projectId: "fitsane-3d448",
  storageBucket: "fitsane-3d448.appspot.com",
  messagingSenderId: "416289989321",
  appId: "1:416289989321:web:fd48ae11addc42f91f2318",
};

const app = initializeApp(firebaseConfig);
export default app;
