import MDBox from "components/MDBox";
import PageLayout from "examples/LayoutContainers/PageLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import bgImage from "assets/images/bg-home.jpg";
import React from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function Homepage() {
  return (
    <PageLayout background="white">
      <div>
        <DashboardNavbar />
        <MDBox>
          <MDBox
            width="-webkit-fill-available"
            minHeight="91vh"
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                bgImage &&
                `${linearGradient(
                  rgba(gradients.dark.main, 0.6),
                  rgba(gradients.dark.state, 0.6)
                )}, url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", height: "90vh" }}>
              <Grid item xs={12} xl={5} pl={10}>
                <MDTypography
                  variant="h3"
                  color="white"
                  fontWeight="medium"
                  textTransform="capitalize"
                  pb={2}
                >
                  FitSane is the solution to all your fitness questions. Watch fitness reels to
                  boost your motivation, know about fitness events, information about exercises for
                  various parts of the body and a lot more. So what are you waiting for? Join today!
                </MDTypography>
                <MDButton
                  component={Link}
                  to="/signup"
                  variant="gradient"
                  color="info"
                  size="large"
                >
                  Sign Up Now
                </MDButton>
              </Grid>
            </div>
          </MDBox>
        </MDBox>
      </div>
    </PageLayout>
  );
}

export default Homepage;
