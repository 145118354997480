import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FeedCard from "examples/Cards/FeedCard";
import Header from "layouts/profile/components/Header";
import { useEffect, useContext, useState } from "react";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import app from "utils/firebase";
import { UserContext } from "context/UserContext";
import NewReel from "./components/NewReel";
import "./style.css";

function Overview() {
  const { user } = useContext(UserContext);
  const db = getFirestore(app);
  const [userVideos, setUserVideos] = useState([]);

  const getUserVidId = async () => {
    getDoc(doc(db, "users", user.uid))
      .then(async (querySnapshot) => {
        if (querySnapshot.exists()) {
          const uData = querySnapshot.data();
          if (uData.posts_created.length !== 0) {
            const arr = [];
            let i = 0;
            while (uData?.posts_created[i]) {
              const postSnapshot = await getDoc(doc(db, "posts", uData?.posts_created[i])); // eslint-disable-line
              if (postSnapshot.exists()) {
                const postData = postSnapshot.data();
                arr.push(postData);
              }
              i += 1;
            }
            setUserVideos(arr);
          } else {
            setUserVideos([]);
          }
        }
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
      });
  };

  useEffect(() => {
    if (user && user.uid) {
      getUserVidId();
    }
  }, [user]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={5}>
            <Grid item xs={12} xl={8}>
              <Grid container spacing={2}>
                {userVideos &&
                  userVideos.length !== 0 &&
                  userVideos.map((videoD) => (
                    <Grid item key={videoD.pid} xs={12} md={6} xl={4} style={{ height: "500px" }}>
                      <FeedCard
                        video={videoD.videoLink}
                        likes={videoD.likes}
                        uid={videoD.uid}
                        id={videoD.pid}
                        userMail={videoD.email}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
            <Grid item xs={12} xl={4}>
              <NewReel userVideos={userVideos} setUserVideos={setUserVideos} />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
