import React, { useState } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Card, Grid, Icon } from "@mui/material";
import data from "assets/exercise.json";
import MDTypography from "components/MDTypography";
import ReactPlayer from "react-player";

function Exercise() {
  const [showSub, setShowSub] = useState(true);
  const [subData, setSubData] = useState({
    part: "",
    exercise: [],
  });
  const handleClick = (part, exercise) => {
    setShowSub(false);
    setSubData({
      part,
      exercise,
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {showSub ? (
        <Grid container xl={12} spacing={5} mt={1}>
          {data &&
            data.map((item, i) => (
              <Grid item xs={12} xl={3} key={`${item.part}-${i}`}> {/* eslint-disable-line */}
                <Card>
                  <MDBox
                    pt={3}
                    pb={3}
                    px={3}
                    onClick={() => handleClick(item.part, item.exercises)}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      {item.part.toUpperCase()}
                    </div>
                  </MDBox>
                </Card>
              </Grid>
            ))}
        </Grid>
      ) : (
        <Grid container xl={12} spacing={5}>
          <Grid item xs={12} xl={12}>
            <Grid item xs={3} xl={3}>
              <MDTypography
                variant="h4"
                fontWeight="medium"
                color="inherit"
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <Icon onClick={() => setShowSub(true)} style={{ cursor: "pointer" }}>
                  arrow_back
                </Icon>
                {subData.part.toUpperCase()}
              </MDTypography>
            </Grid>
          </Grid>
          {subData.exercise &&
            subData.exercise.map((item, i) => (
              <Grid item xs={12} xl={3} key={`${item.name}-${i}`}> {/* eslint-disable-line */}
                <Card>
                  <MDBox pt={3} pb={3} px={3}>
                    <MDTypography variant="h4" fontWeight="medium" color="inherit">
                      {item.name.toUpperCase()}
                    </MDTypography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {item.desc}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ReactPlayer url={item.videos[0]} controls height="200px" />
                    </div>
                  </MDBox>
                </Card>
              </Grid>
            ))}
        </Grid>
      )}
    </DashboardLayout>
  );
}

export default Exercise;
