import React from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Card, Divider, Grid, Icon } from "@mui/material";
import data from "assets/events.json";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function Events() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4.5}>
        <Grid container spacing={5}>
          {data &&
            data.map((item, i) => (
              <Grid item xs={12} md={6} lg={4} key={`${item.name}-${i}`}> {/* eslint-disable-line */}
                <MDBox mb={3}>
                  <Card sx={{ height: "100%" }}>
                    <MDBox padding="1rem">
                      <MDBox
                        variant="gradient"
                        borderRadius="lg"
                        coloredShadow="dark"
                        mt={-5}
                        height="12.5rem"
                        style={{
                          backgroundColor: "#000",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "0.6rem",
                        }}
                      >
                        <img
                          src={item.image}
                          alt={item.name}
                          style={{ width: "100%", height: "192px" }}
                        />
                      </MDBox>
                      <MDBox pt={3} pb={1} px={1}>
                        <MDTypography variant="h6" textTransform="capitalize">
                          <Icon>event</Icon>
                          {` `}
                          {item.name}
                        </MDTypography>
                        <Divider />
                        <MDBox display="flex" alignItems="center" justifyContent="space-between">
                          <MDBox display="flex" alignItems="center">
                            <MDTypography
                              variant="button"
                              color="text"
                              lineHeight={1}
                              sx={{ mt: 0.15, mr: 0.5 }}
                            >
                              <Icon>schedule</Icon>
                            </MDTypography>
                            <MDTypography variant="button" color="text" fontWeight="light">
                              {item.date} | {item.time}
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" alignItems="center">
                            <MDButton
                              variant="gradient"
                              color="info"
                              fullWidth
                              onClick={() => {
                                window.open(`${item.regLink}`, "_blank");
                              }}
                            >
                              Register Now
                            </MDButton>
                          </MDBox>
                        </MDBox>
                        <MDBox display="flex" alignItems="center">
                          <MDBox display="flex" alignItems="center">
                            <MDTypography
                              variant="button"
                              color="text"
                              lineHeight={1}
                              sx={{ mt: 0.15, mr: 0.5 }}
                            >
                              <Icon>place</Icon>
                            </MDTypography>
                            <MDTypography variant="button" color="text" fontWeight="light">
                              {item.venue}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </MDBox>
              </Grid>
            ))}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Events;
