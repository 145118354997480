import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { IconButton } from "@mui/material";
import { UserContext } from "context/UserContext";
import { arrayRemove, arrayUnion, doc, getFirestore, updateDoc } from "firebase/firestore";
import app from "utils/firebase";

function FeedCard({ video, likes, id, userMail }) {
  const db = getFirestore(app);
  const { user } = useContext(UserContext);
  const videoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false); // eslint-disable-line
  const [sameLike, setSameLike] = useState(false);
  const [likeData, setLikeData] = useState([]);
  // console.log(likes); // eslint-disable-line
  useEffect(() => {
    if (likes?.includes(user.uid)) {
      setSameLike(true);
    } else {
      setSameLike(false);
    }
    setLikeData(likes);
  }, []);
  const handleClick = (e) => {
    e.preventDefault();
    // e.target.muted = !e.target.muted;
    if (isVideoPlaying) {
      videoRef.current.pause();
      e.target.muted = true;
    } else {
      videoRef.current.play();
      e.target.muted = false;
    }
    setIsVideoPlaying(!isVideoPlaying);
  };
  const handleScroll = (e) => {
    // eslint-disable-next-line
    const next = ReactDOM.findDOMNode(e.target).parentNode.nextSibling;
    if (next) {
      next.scrollIntoView();
      e.target.muted = false;
      e.target.play = true;
    }
  };
  const handleLike = () => {
    if (sameLike) {
      // DisLike
      updateDoc(doc(db, "posts", id), {
        likes: arrayRemove(user.uid),
      });
      setSameLike(false);
      setLikeData((like) => like.filter((a) => a !== user.uid));
    } else {
      // Like
      updateDoc(doc(db, "posts", id), {
        likes: arrayUnion(user.uid),
      });
      setSameLike(true);
      setLikeData([...likeData, user.uid]);
    }
  };
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        scrollSnapAlign: "start",
      }}
      className="post_video"
    >
      {/* eslint-disable-next-line */}
      <video
        onClick={handleClick}
        onEnded={handleScroll}
        src={video}
        ref={videoRef}
        id={id}
        alt="video"
        style={{ width: "100%", height: "100%", objectFit: "fill", borderRadius: "15px" }}
      />
      <div
        style={{
          position: "absolute",
          bottom: "0",
          padding: "0.5rem 1.5rem",
          backgroundColor: "rgba(0,0,0,0.3)",
          width: "100%",
          borderBottomLeftRadius: "15px",
          borderBottomRightRadius: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "rgba(255,255,255,1)",
        }}
      >
        <div style={{ width: "170px", overflow: "hidden", height: "35px" }}>
          {userMail.replace("@gmail.com", "")}
        </div>
        <IconButton aria-label="fingerprint" onClick={handleLike} style={{ color: "#ef5350" }}>
          {sameLike ? <FavoriteIcon /> : <FavoriteBorderIcon />} &nbsp; {likeData.length}
        </IconButton>
      </div>
    </div>
  );
}

// Typechecking props for the FeedCard
FeedCard.propTypes = {
  id: PropTypes.string.isRequired,
  userMail: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  likes: PropTypes.array.isRequired,
  // uid: PropTypes.any.isRequired, // react/forbid-prop-types
};

export default FeedCard;
