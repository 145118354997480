import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { MaterialUIControllerProvider } from "context";
import AuthContext from "context/UserContext";

ReactDOM.render(
  <BrowserRouter>
    <AuthContext>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </AuthContext>
  </BrowserRouter>,
  document.getElementById("root")
);
